//styles
import './SubTitle.css';

const SubTitle = ({text}) => {
  return (
    <h2 className='sub-title'>
        {text}
    </h2>
  );
}

export default SubTitle;