import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import locationService from "../services/locationService";

const initialState = {
  states: [],
  counties: [],
  error: false,
  success: false,
  loading: false,
};

export const getAllStates = createAsyncThunk("location/getallstates", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await locationService.getStates(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const getAllCounties = createAsyncThunk("location/getallcounties", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await locationService.getCounties(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const locationSlice = createSlice({
  name: "location",
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(getAllStates.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllStates.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.states = action.payload;
      })
      .addCase(getAllStates.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAllCounties.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllCounties.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.counties = action.payload;
      })
      .addCase(getAllCounties.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default locationSlice.reducer;