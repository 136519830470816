//styles
import './Navbar.css';

//icons
import { FiLogOut } from 'react-icons/fi';

//hooks
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

//redux
import { logout, reset } from "../../slices/authSlice";

const Navbar = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(logout());
    dispatch(reset());

    navigate("/login");
  };

  return (
    <nav className='navbar'>
        <button onClick={handleLogout}>
            <FiLogOut />
            Salir
        </button>
    </nav>
  );
}

export default Navbar;