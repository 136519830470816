//styles
import './TextArea.css';

const TextArea = ({label, name, method, value, required}) => {
  return (
    <label className='text-area'>
        {label && <span>{label}</span>}
        <textarea 
            name={name} 
            onChange={method} 
            value={value}
            required={required}
            rows="4"
        ></textarea>
    </label>
  );
}

export default TextArea;