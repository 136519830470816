import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import clientService from "../services/clientService";

const initialState = {
  customers: [],
  client: {},
  error: false,
  success: false,
  loading: false,
  message: null,
  reloadTable: false,
};

export const getClient = createAsyncThunk("client/get", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await clientService.getClient(id, token);

  if(data.errors)
  return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const getAllCustomers = createAsyncThunk("client/getall", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await clientService.getAllCustomers(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const registerClient = createAsyncThunk( "client/register", async (client, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await clientService.registerClient(client, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const updateClient = createAsyncThunk( "client/update", async (client, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await clientService.updateClient(client, client.id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const deleteClient = createAsyncThunk("client/delete", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await clientService.deleteClient(id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const clientSlice = createSlice({
  name: "client",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = false;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteClient.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = true;
      })
      .addCase(deleteClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerClient.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(registerClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(updateClient.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(updateClient.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(updateClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(getAllCustomers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = false;
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.customers = action.payload;
      })
      .addCase(getAllCustomers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getClient.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.client = action.payload;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetMessage, resetError, resetSuccess } = clientSlice.actions;
export default clientSlice.reducer;