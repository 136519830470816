import { api, requestConfig } from "../utils/config";

const getStates = async (token) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/location/states", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.log(error);
  }
};

const getCounties = async (token) => {
  const config = requestConfig("GET", null, token);

  try {
    const res = await fetch(api + "/location/counties", config)
      .then((res) => res.json())
      .catch((err) => err);

    return res;
  } catch (error) {
    console.log(error);
  }
};

const locationService = {
    getStates,
    getCounties
};

export default locationService;