import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import userService from "../services/userService";

const initialState = {
  users: [],
  user: {},
  error: false,
  success: false,
  loading: false,
  message: null,
  reloadTable: false,
};

export const getUser = createAsyncThunk("user/get", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await userService.getUser(id, token);

  if(data.errors)
  return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const getAllUsers = createAsyncThunk("user/getall", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await userService.getAllUsers(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const registerUser = createAsyncThunk( "user/register", async (user, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await userService.registerUser(user, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const updateUser = createAsyncThunk( "user/update", async (user, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await userService.updateUser(user, user.id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const deleteUser = createAsyncThunk("user/delete", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await userService.deleteUser(id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = false;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = true;
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(getAllUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = false;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.users = action.payload;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getUser.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.user = action.payload;
      })
      .addCase(getUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetMessage, resetError, resetSuccess } = userSlice.actions;
export default userSlice.reducer;