import Select from 'react-select';

const SelectList = ({list, label, value, method, disabled}) => {
  const defaultValue = list.find((option) => option.value === value);

  const customFilter = (option, inputValue) => {
    return (
      option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const sortOptionsAlphabetically = (options) => {
    return options.slice().sort((a, b) => a.label.localeCompare(b.label));
  };

  return (
    <label className='select'>
        {label && <span>{label}</span>}
    <Select 
        className='select-list'
        onChange={method}
        value={defaultValue}
        isMulti={false}
        options={sortOptionsAlphabetically(list)}
        isSearchable={true}
        filterOption={customFilter}
        isDisabled={disabled}
        maxMenuHeight={100}
        placeholder="Seleccionar..."
    />
    </label>
  )
}

export default SelectList;