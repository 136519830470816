import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import productService from "../services/productService";

const initialState = {
  products: [],
  product: {},
  error: false,
  success: false,
  loading: false,
  message: null,
  reloadTable: false,
};

export const getProduct = createAsyncThunk("product/get", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await productService.getProduct(id, token);

  if(data.errors)
  return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const getAllProducts = createAsyncThunk("product/getall", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await productService.getAllProducts(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const registerProduct = createAsyncThunk( "product/register", async (product, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await productService.registerProduct(product, token);

    if (data.errors)
      return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
  }
);

export const updateProduct = createAsyncThunk( "product/update", async (product, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await productService.updateProduct(product, product.id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const deleteProduct = createAsyncThunk("product/delete", async (id, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await productService.deleteProduct(id, token);

    if (data.errors)
      return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = false;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = true;
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(registerProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(registerProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(updateProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(updateProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(updateProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(getAllProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = false;
      })
      .addCase(getAllProducts.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.products = action.payload;
      })
      .addCase(getAllProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.product = action.payload;
      })
      .addCase(getProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetMessage, resetError, resetSuccess } = productSlice.actions;
export default productSlice.reducer;