//components
import Sidebar from '../components/Sidebar/Sidebar';
import Navbar from '../components/Navbar/Navbar';

const Dashboard = ({ children }) => {
  return (
    <>
      <Sidebar />
      <div className="app-container">
        <Navbar />
        {children}
      </div>
    </>
  );
};

export default Dashboard;