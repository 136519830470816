import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import saleService from "../services/saleService";

const initialState = {
  sales: [],
  sale: {},
  error: false,
  success: false,
  loading: false,
  message: null,
  reloadTable: false
};

export const getSale = createAsyncThunk("sale/get", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await saleService.getSale(id, token);

  if(data.errors)
  return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const getAllSales = createAsyncThunk("sale/getall", async (_, thunkAPI) => {
    const token = thunkAPI.getState().auth.user.token;
    const data = await saleService.getAllSales(token);

    if(data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

    return data;
});

export const registerSale = createAsyncThunk( "sale/register", async (sale, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await saleService.registerSale(sale, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const updateSale = createAsyncThunk( "sale/update", async (sale, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await saleService.updateSale(sale, sale.id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const deleteSale = createAsyncThunk("sale/delete", async (id, thunkAPI) => {
  const token = thunkAPI.getState().auth.user.token;
  const data = await saleService.deleteSale(id, token);

  if (data.errors)
    return thunkAPI.rejectWithValue(data.errors[0]);

  return data;
});

export const saleSlice = createSlice({
  name: "sale",
  initialState,
  reducers: {
    resetMessage: (state) => {
      state.message = null;
    },
    resetError: (state) => {
      state.error = false;
    },
    resetSuccess: (state) => {
      state.success = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(deleteSale.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSale.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = true;
      })
      .addCase(deleteSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(registerSale.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(registerSale.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(registerSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(updateSale.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = true;
      })
      .addCase(updateSale.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.message = action.payload.message;
        state.reloadTable = false;
      })
      .addCase(updateSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.reloadTable = false;
      })
      .addCase(getAllSales.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.reloadTable = false;
      })
      .addCase(getAllSales.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.sales = action.payload;
      })
      .addCase(getAllSales.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSale.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSale.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
        state.error = null;
        state.sale = action.payload;
      })
      .addCase(getSale.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export const { resetMessage, resetError, resetSuccess } = saleSlice.actions;
export default saleSlice.reducer;