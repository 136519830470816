//styles
import './Login.css'

//images
import Logo from '../../assets/logo.jpg'

//hooks
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';

// redux
import { login, reset } from '../../slices/authSlice';

//components
import Button from '../../components/Button/Button';
import Input from '../../components/Input/Input';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.auth);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const user = {
      username,
      password
    }

    dispatch(login(user));
  };

  useEffect(() => {
    dispatch(reset());
  }, [dispatch]);

  return (
    <div className='login'>
      <div className='logo'>
        <img src={Logo} alt="bvhome" />
      </div>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h1>Login</h1>
        {error && <p className="error">{error}</p>}  
        <Input 
          label='Nombre de usuario:'
          type='text'
          method={(e) => setUsername(e.target.value)}
          value={username}
        />
        <Input 
          label='Contraseña:'
          type='password'
          method={(e) => setPassword(e.target.value)}
          value={password}
        />
        <div className='action'>
          {!loading && <Button text='Entrar' />}
          {loading && <Button text='Aguarde...' disabled />} 
        </div>
       </form>
    </div>
  );
}

export default Login;