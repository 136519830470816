//styles
import './Title.css';

const Title = ({text}) => {
  return (
    <h1 className='title'>
      {text}
    </h1>
  );
}

export default Title;