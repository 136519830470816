//styles
import './Input.css';

const Input = ({label, type, name, placeholder, method, value, required, max, min, disabled}) => {
  return (
    <label className='input'>
        {label && <span>{label}</span>}
        <input 
          type={type} 
          name={name} 
          placeholder={placeholder}
          onChange={method} 
          value={value}
          required={required}
          disabled={disabled}
          autocomplete="new-password"
          min={min}
          max={max}
        />
    </label>
  );
}

export default Input;