//styles
import './Select.css';

const Select = ({label, name, options, value, method, required}) => {
  return (
    <label className='select'>
        {label && <span>{label}</span>}
        <select name={name} value={value} onChange={method} required={required}>
            {options && options.map((option, index) => (
                <option key={index} value={option}>
                    {option}
                </option>
            ))}
        </select>
    </label>
  );
}

export default Select;