//styles
import './Button.css';

const Button = ({text, type, method, disabled}) => {
  return (
    <button className='btn' type={type} onClick={method} disabled={disabled} >
        {text}
    </button>
  );
}

export default Button;